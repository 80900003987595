import React from 'react';
import WebViewAppBar from '../../../components/WebViewAppBar';
import PurchaseSubscriptionPaymentBody from './PurchaseSubscriptionPaymentBody';

import styles from './PurchaseSubscriptionPayment.module.scss';
import { useHistory } from 'react-router-dom';

export default function PurchaseSubscriptionPayment() {
  const history = useHistory();

  return (
    <div className={styles.paymentContainer}>
      <WebViewAppBar />
      <h1>Payment</h1>
      <PurchaseSubscriptionPaymentBody includeCancel onSubmit={history.goBack} />
    </div>
  );
}
