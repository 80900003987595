import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import styles from './InAppCheckoutModal.module.scss';
import { useQuery } from '@apollo/client';

import { ReactComponent as CloseIcon } from '../../../../../assets/images/icons/close.svg';
import { PurchaseType, SupplementCartItem, SUPPLEMENT_BAG_IMAGES, getCartSKUs } from '../UpsellV2/UpsellV2';
import { ReactComponent as TrashIcon } from '../../../../../assets/images/icons/trash.svg';
import { getMultipleSupplementSubscriptionsPricingQuery } from '../../../../../graphql-operations/Subscription.graphql';
import * as types from '../../../../../types';
import { formattedCentsToDollars, getLoadingOrErrorElement } from '../../../../../lib/util';

interface IInAppCheckoutModalProps {
  onPurchase: () => void;
  onOpen?: () => void;
  onClose: () => void;
  trigger: React.ReactElement;
  open: boolean;
  cartItems: SupplementCartItem[];
  fiveDollarFirstBagOfferLive: boolean; // Dictates whether or not to show the $5 first bag promotion offer
  onRemoveItem?: (index: number, close: () => void) => void;
  isUpsellLayout?: boolean;
}

export const getItemPrice = (purchaseType: PurchaseType): number => {
  return purchaseType === PurchaseType.Autoship ? 25 : 30;
};

export default function InAppCheckoutModal({
  onPurchase,
  onOpen,
  onClose,
  trigger,
  open,
  cartItems,
  onRemoveItem,
  isUpsellLayout,
  fiveDollarFirstBagOfferLive,
}: IInAppCheckoutModalProps) {
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const { data, loading, error } = useQuery<
    types.gqlTypes.ECOMMERCE_getMultipleSupplementSubscriptionsPricing,
    types.gqlTypes.ECOMMERCE_getMultipleSupplementSubscriptionsPricingVariables
  >(getMultipleSupplementSubscriptionsPricingQuery, {
    variables: { input: { skusToAdd: getCartSKUs(cartItems), forceApplyDiscount: isUpsellLayout } },
    skip: cartItems.length === 0,
  });

  const cartContainsDiscountEligibleItems = cartItems.some((item) => item.eligibleForDiscount);
  const tax = formattedCentsToDollars(data?.getMultipleSupplementSubscriptionsPricing?.taxInCents || 0);
  const totalPrice = formattedCentsToDollars(data?.getMultipleSupplementSubscriptionsPricing?.totalInCents || 0);
  const showFiveDollarOffer = cartContainsDiscountEligibleItems && fiveDollarFirstBagOfferLive;

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, error);
  if (loadingOrErrorElement) {
    return (
      <Popup className="s3-popup" open={open} onClose={onClose} trigger={trigger} modal>
        {() => loadingOrErrorElement}
      </Popup>
    );
  }

  return (
    <Popup className="s3-popup" open={open} onOpen={onOpen} onClose={onClose} trigger={trigger} modal>
      {(close: () => void) => (
        <div className={styles.container}>
          {!isUpsellLayout && (
            <div className={styles.header}>
              <div className={styles.title}>Check out</div>
              <div className={styles.closeIcon}>
                <CloseIcon onClick={() => close()} />
              </div>
            </div>
          )}
          <div className={styles.body}>
            <div className={styles.cartItemsContainer}>
              {cartItems.map((item, index) => (
                <div className={styles.cartItem} key={index}>
                  <div className={styles.cartItemDetails}>
                    {!isUpsellLayout && (
                      <div className={styles.cartItemImage}>
                        <img src={SUPPLEMENT_BAG_IMAGES[item.skuType].url} alt={item.skuType} />
                      </div>
                    )}
                    <div className={styles.cartItemText}>
                      <div className={styles.cartItemTitle}>{item.skuType}</div>
                      {!isUpsellLayout && item.purchaseType === PurchaseType.Autoship && (
                        <div className={styles.cartItemDescription}>
                          Auto ships every {item.frequency === 1 ? 'week' : `${item.frequency} weeks`}
                        </div>
                      )}
                      {isUpsellLayout && showFiveDollarOffer && (
                        <div className={styles.cartItemDescription}>
                          Ships every {item.frequency === 1 ? 'week' : `${item.frequency} weeks`} for{' '}
                          <span>{formattedCentsToDollars(2500)}</span>
                        </div>
                      )}
                      {isUpsellLayout && !showFiveDollarOffer && item.purchaseType === PurchaseType.Autoship && (
                        <div className={styles.cartItemDescription}>
                          Ships every {item.frequency === 1 ? 'week' : `${item.frequency} weeks`}{' '}
                          <span className={styles.cartItemDescriptionCrossedOut}>
                            {formattedCentsToDollars(2500 / item.frequency!)}
                          </span>{' '}
                          <span>{formattedCentsToDollars(1500 / item.frequency!)}/week</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {!isUpsellLayout && (
                    <div className={styles.cartItemPrice}>
                      <div className={styles.cartItemPriceValue}>${getItemPrice(item.purchaseType)}</div>
                      {onRemoveItem && (
                        <div className={styles.cartItemRemoveButton} onClick={() => onRemoveItem(index, close)}>
                          <TrashIcon />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
            {cartContainsDiscountEligibleItems && (
              <div className={styles.promotionContainer}>
                <div className={styles.promotionTitle}>First bag special promotion</div>
                <div className={styles.promotionValue}>{showFiveDollarOffer ? '-$20' : '-$10'}</div>
              </div>
            )}
            <div className={styles.taxContainer}>
              <div className={styles.taxTitle}>Tax:</div>
              <div className={styles.taxValue}>{tax}</div>
            </div>
            <div className={styles.totalDueContainer}>
              <div className={styles.totalDueTitle}>Total due today:</div>
              <div className={styles.totalDueValue}>{totalPrice}</div>
            </div>
            <button
              className={styles.confirmOrderButton}
              onClick={() => {
                setConfirmButtonDisabled(true);
                onPurchase();
              }}
              disabled={confirmButtonDisabled}
            >
              Confirm order
            </button>
            {!isUpsellLayout && (
              <>
                <button className={styles.continueShoppingButton} onClick={() => close()}>
                  Continue shopping
                </button>
                <div className={styles.disclaimer}>
                  By taping Confirm Order you accept the{' '}
                  <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
                    Terms & Conditions.
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Popup>
  );
}
