import { getTenancy } from '../hooks/useTenancy';
import { RecaptchaError } from './errors';

type RecaptchaAction = 'signup' | 'payment_add' | 'checkout';

const TEST_TENANCY_TOKEN = 'test';

export function fetchRecaptchaToken(action: RecaptchaAction) {
  const tenancy = getTenancy();

  return new Promise<string>((resolve, reject) => {
    // No need to execute reCAPTCHA for test tenancy.
    if (tenancy === 'test') {
      resolve(TEST_TENANCY_TOKEN);
      return;
    }

    grecaptcha.enterprise.ready(() => {
      try {
        grecaptcha.enterprise
          .execute(process.env.REACT_APP_RECAPTCHA_KEY!, {
            action,
          })
          .then(
            (recaptchaToken) => resolve(recaptchaToken),
            // Unsure if this is used (see catch below), but type definitions specify an onRejected callback.
            (error) => reject(error),
          );
      } catch (error) {
        // grecaptcha.enterprise.execute returns a PromiseLike but seems like I can't chain .catch() on it.
        // When I force an error by using an invalid reCAPTCHA key, the onRejected callback (second argument)
        // passed to .then() doesn't trigger. Instead, an error is thrown.
        reject(new RecaptchaError(error.message));
      }
    });
  });
}
