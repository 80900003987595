import React, { useEffect } from 'react';
import styles from './Pack.module.scss';
import JoinPackConfirmationModal from './JoinPackConfirmationModal';
import Button from '../../components/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlTypes } from '../../types';
import { getPackQuery, joinPackMutation, petsWithActiveSubscriptionQuery } from '../../graphql-operations';
import { Image } from '../../components/Image';
import CollarHero from '../../assets/images/collar_hero_blue_3.png';
import { getLoadingOrErrorElement } from '../../lib/util/getLoadingOrErrorElement';
import { joinPack as joinPackEvents } from '../../lib/analytics/events';
import ErrorMessage, { useError } from '../../components/ErrorMessage';
import AppPaths from '../../AppPaths';

export default function JoinPack() {
  useEffect(() => joinPackEvents.viewed(), []);

  const { error, errorID, setError } = useError();

  const { packId } = useParams<{ packId: string }>();
  const petsQuery = useGetPetsWithActiveSubscriptionQuery(setError);

  const getPackQueryResult = useGetPackQuery(packId, setError);
  const packName = getPackQueryResult.data?.pack?.name;

  const petIds = petsQuery.data?.petsWithActiveSubscription.map((pet) => pet.id) || [];

  const joinPack = useJoinPackMutation(petIds, packId, setError, packName);

  const triggerJoinPackModal = (
    <Button
      className={styles.simpleButton}
      disabled={getPackQueryResult.loading}
      onClick={() => joinPackEvents.confirmationModalOpen()}
    >
      Join the pack
    </Button>
  );

  const getPetsLoadingOrErrorElement = getLoadingOrErrorElement(petsQuery.loading, petsQuery.error);
  if (getPetsLoadingOrErrorElement) {
    return getPetsLoadingOrErrorElement;
  }

  return (
    <>
      <div className={styles.simpleFullWidthWrapper}>
        <div className={styles.simpleContainer}>
          <div className={styles.specialOfferChip} />
          <div className={styles.simpleHeaderContainer}>
            <h1 className={styles.simpleHeader}>Join the pack</h1>
            <p className={styles.simpleSubheader}>{packName}</p>
          </div>
          <div className={styles.collarContainer}>
            <Image className={styles.collarImage} image={{ type: 'image', url: CollarHero }} />
          </div>
          <JoinPackConfirmationModal
            packName={packName}
            trigger={triggerJoinPackModal}
            onAccept={() => {
              joinPack();
            }}
            onDecline={() => {
              joinPackEvents.confirmationModalGoBack();
            }}
          />
          {error && <ErrorMessage errors={[error]} errorID={errorID} />}
        </div>
      </div>
    </>
  );
}

function useGetPetsWithActiveSubscriptionQuery(setError: (error: string) => void) {
  return useQuery<
    gqlTypes.ECOMMERCE_petsWithActiveSubscription,
    gqlTypes.ECOMMERCE_petsWithActiveSubscriptionVariables
  >(petsWithActiveSubscriptionQuery, {
    onError(err) {
      setError(err.message);
    },
  });
}

function useGetPackQuery(packId: string, setError: (error: string) => void) {
  return useQuery<gqlTypes.ECOMMERCE_pack, gqlTypes.ECOMMERCE_packVariables>(getPackQuery, {
    variables: { packId },
    onError(err) {
      setError(err.message);
    },
  });
}

function useJoinPackMutation(petIds: string[], packId: string, setError: (error: string) => void, packName?: string) {
  const history = useHistory();

  const [partialJoinPackMutation] = useMutation<gqlTypes.ECOMMERCE_joinPack, gqlTypes.ECOMMERCE_joinPackVariables>(
    joinPackMutation,
  );

  return () =>
    Promise.all(
      petIds.map((petId) => {
        return partialJoinPackMutation({
          variables: { petId, packId },
        });
      }),
    )
      .then(() => {
        history.push({
          pathname: AppPaths.Packs.Complete(packId),
          search: window.location.search,
          state: { packName: packName },
        });
      })
      .catch((err) => {
        setError(err.message);
      });
}
