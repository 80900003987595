import { gql } from '@apollo/client';

const addressFragment = gql`
  fragment fullAddress on Address {
    line1
    line2
    city
    state
    zip
    country
    phone
  }
`;

export const updateShippingAddressMutation = gql`
  mutation updateShippingAddress(
    $firstName: String!
    $lastName: String
    $address: AddressInput!
    $billingInfo: BillingInfoInput
    $recaptchaToken: String
  ) {
    updateBillingAccount(
      input: { address: $address, billingInfo: $billingInfo, firstName: $firstName, lastName: $lastName }
      recaptchaToken: $recaptchaToken
      trackEvent: true
    ) {
      firstName
      lastName
      address {
        ...fullAddress
      }
    }
  }

  ${addressFragment}
`;

export const validateAddressQuery = gql`
  query validateAddress($address: AddressInput!) {
    validateAddress(address: $address) {
      status
      matchedAddress {
        ...fullAddress
      }
    }
  }

  ${addressFragment}
`;
