import { MutableRefObject, useState, useMemo, useEffect } from 'react';

/**
 *
 * @param ref element to track
 * @returns true of false indicating whether element is not on screen (~20% out of view)
 */
export default function useElementOnScreen(ref: MutableRefObject<HTMLDivElement | null>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(
      ([entry]) => {
        const isNowIntersecting = entry.isIntersecting;
        setIntersecting((prev) => (prev !== isNowIntersecting ? isNowIntersecting : prev));
      },
      {
        rootMargin: '0px 0px -20% 0px', // Triggers "not on screen" when element is ~20% out of view
        threshold: 0.1,
      },
    );
  }, []);

  useEffect(() => {
    const current = ref.current;
    if (!current) return;

    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, [ref, observer]);

  return isIntersecting;
}
