import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import ErrorMessage, { useError } from '../../../../components/ErrorMessage';
import Loading from '../../../../components/Loading';
import { validatePayPalTender } from '../../../../components/PayPalButton';
import { billingAccountQuery, updateBillingInfoMutation } from '../../../../graphql-operations';
import useCheckoutPaths from '../../../../hooks/useCheckoutPaths';
import CheckoutContext from '../../../../lib/CheckoutContext';
import * as events from '../../../../lib/analytics/events';
import ErrorMessages, { getCustomerMessageFromApolloError, logInternalError } from '../../../../lib/errors';
import CheckoutSection from '../../../../models/CheckoutSection';
import * as types from '../../../../types';
import { gqlTypes } from '../../../../types';
import StepContainer from '../../components/StepContainer';
import styles from './Billing.module.scss';
import BillingInfo, { UpdateBillingInfoFunction } from './BillingInfo';

export default function Billing() {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector((state: types.AppState) => state.config.products);
  const checkoutPaths = useCheckoutPaths();

  const { cart, checkoutState, checkoutActions, requiresShippingDetails, session } = useContext(CheckoutContext);

  const { error, errorID, setError, clearError } = useError();

  const [mutation, { loading: mutationLoading }] = useMutation<
    gqlTypes.updateBillingInfo,
    gqlTypes.updateBillingInfoVariables
  >(updateBillingInfoMutation);

  const onDone = useCallback(() => {
    events.payment.continue(cart.cartItems);
    history.push(checkoutPaths.Review);
  }, [cart.cartItems, checkoutPaths.Review, history]);

  // Handle updating the billing information and checkout context
  const handleUpdateBillingInfo: UpdateBillingInfoFunction = useCallback(
    async ({ input, recaptchaToken }) => {
      // Prevent double-submit if an existing mutation request is already in progress
      if (mutationLoading) {
        return;
      }

      try {
        const result = await mutation({
          variables: {
            input,
            recaptchaToken,
          },
        });

        const newBillingInfo = result.data?.updateBillingAccount?.billingInfo;
        if (newBillingInfo) {
          dispatch(checkoutActions.setBillingInfo({ billingInfo: newBillingInfo }));
          onDone();
        } else {
          throw new Error('No updated billing information');
        }
      } catch (err) {
        const customerErrorMessage = getCustomerMessageFromApolloError(err);
        if (customerErrorMessage) {
          setError(customerErrorMessage);
        } else {
          logInternalError(err, {
            fingerprint: ['{{ default }}', err.message],
            tags: {
              paymentMethod: 'creditCard',
              checkoutStep: 'updateBillingInfo',
            },
          });
          setError(ErrorMessages.INPUT_ERROR);
        }

        events.payment.continueError(err.message);
      }
    },
    [mutationLoading, mutation, dispatch, checkoutActions, onDone, setError],
  );

  const hasFinishedPreviousStep = useMemo(() => {
    if (requiresShippingDetails) {
      // User should have filled out shipping information before they get to billing
      return !!checkoutState.shippingAddress;
    } else {
      // If we don't need shipping details for this cart, we need to make sure they're logged in. Account creation
      // is also on the previous step.
      return !!session;
    }
  }, [checkoutState.shippingAddress, requiresShippingDetails, session]);

  // Query for the latest billing information
  const { loading: queryLoading, data } = useQuery<gqlTypes.billingAccount>(billingAccountQuery, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      const customerErrorMessage = getCustomerMessageFromApolloError(err);
      if (customerErrorMessage) {
        setError(customerErrorMessage);
      } else {
        logInternalError(err, {
          fingerprint: ['{{ default }}', err.message],
          tags: {
            paymentMethod: 'creditCard',
            checkoutStep: 'billing',
          },
        });
        setError(ErrorMessages.DEFAULT);
      }
    },
    // If we haven't finished the previous step, don't bother loading the billing info. Later, we will be redirecting
    // back to the previous step. But that redirect can't happen above here because this useQuery, and all hooks,
    // cannot be conditionally called.
    skip: !hasFinishedPreviousStep || checkoutState.unlinkBillingInfo,
  });

  // Update the Context with the billing information from the API if:
  // 1. There are no existing errors from the component
  // 2. There is billing info from the API
  // 3. The API billing info is different from what's in the context
  // 4. The API billing info is valid for the current checkout
  const cartItems = Object.values(cart.cartItems);
  const billingInfoFromApi = data?.currentUser?.billingAccount?.billingInfo ?? null;
  useEffect(() => {
    if (!checkoutState.unlinkBillingInfo && !error && billingInfoFromApi && !checkoutState.billingInfo) {
      if (billingInfoFromApi.paymentInfo?.__typename === 'PayPalInfo') {
        const validationError = validatePayPalTender(cartItems, products, events.payment);
        if (validationError) {
          setError(validationError.message);
          return;
        }
      }

      dispatch(checkoutActions.setBillingInfo({ billingInfo: billingInfoFromApi }));
    }
  }, [
    error,
    billingInfoFromApi,
    checkoutState.unlinkBillingInfo,
    checkoutState.billingInfo,
    products,
    setError,
    dispatch,
    checkoutActions,
    cartItems,
  ]);

  if (!hasFinishedPreviousStep) {
    return <Redirect to={checkoutPaths.Shipping} />;
  }

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <StepContainer checkoutSection={CheckoutSection.payment}>
      <div className={styles.container}>
        <BillingInfo
          updateBillingInfo={handleUpdateBillingInfo}
          handleNoChanges={onDone}
          billingInfo={checkoutState.billingInfo ?? billingInfoFromApi ?? null}
          shippingAddress={checkoutState.shippingAddress}
          submitting={mutationLoading}
          setError={setError}
          clearError={clearError}
        />
        {error && <ErrorMessage errors={[error]} errorID={errorID} />}
      </div>
    </StepContainer>
  );
}
