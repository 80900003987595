import { isApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { CaptureContext, ScopeContext } from '@sentry/types';
import { CartPricingValidationErrors, SerializedCartValidationError } from '../types';
import analytics from './analytics';
import { isAxiosError } from './fi-api/apiUtils';

export class UserFacingError extends Error {}
export class RecaptchaError extends Error {}

export class ApplePayAccountExistsError extends UserFacingError {
  constructor() {
    super(
      `Your email address in Apple Pay does not match the email on your account. Please update your email in Apple Pay to match your Fi account.`,
    );
  }
}

export class GenericApplePayError extends UserFacingError {
  constructor() {
    super(`An unknown error occurred. Please try again, or contact support@tryfi.com if the problem persists.`);
  }
}

export const APPLE_PAY_ERROR_CODE_SHIPPING = 'shippingContactInvalid';

// This is a custom error code for apple pay that we will be handling when the error is return from an API call
// to a Fi endpoint.
export const APPLE_PAY_ERROR_CODE_CUSTOM_API = 'fiApiError';
export const APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS = 'accountExists';

export class UserExistsSignUpError extends UserFacingError {
  constructor() {
    super('An account for this email already exists');
  }
}

export class BillingAccountCreationError extends UserFacingError {
  constructor() {
    super('We were unable to finish creating your account');
  }
}

export abstract class PromoCodeError<T> extends Error {
  constructor(message: string, public readonly type: T, public readonly underlying?: any) {
    super(message);
  }

  static notFound<T>(this: { new (message: string, type: 'not-found'): PromoCodeError<T> }, message: string) {
    return new this(message, 'not-found');
  }

  static ineligible<T>(this: { new (message: string, type: 'ineligible'): PromoCodeError<T> }, message: string) {
    return new this(message, 'ineligible');
  }

  static unknown<T>(
    this: { new (message: string, type: 'unknown', underlying: any): PromoCodeError<T> },
    underlying: any,
  ) {
    return new this('An unknown error occurred', 'unknown', underlying);
  }
}

type CouponErrorType = 'not-found' | 'unknown' | 'no-eligible-items' | 'ineligible';

export class CouponError extends PromoCodeError<CouponErrorType> {
  static noEligibleItems(message: string) {
    return new CouponError(message, 'no-eligible-items');
  }
}

type GiftCardErrorType = 'not-found' | 'unknown' | 'purchasing-gift-cards' | 'ineligible';

export class GiftCardError extends PromoCodeError<GiftCardErrorType> {
  static purchasingGiftCards() {
    return new GiftCardError(
      `You cannot use a gift card to purchase another gift card.`,
      'purchasing-gift-cards',
      null,
    );
  }
}

type ReferralCodeErrorType = 'not-found' | 'unknown' | 'code_suspended' | 'ineligible';

export class ReferralCodeError extends PromoCodeError<ReferralCodeErrorType> {
  static codeSuspended() {
    return new ReferralCodeError(`Referral code suspended`, 'code_suspended');
  }
}

export function throwCouponValidationError(error: SerializedCartValidationError): never {
  if (error.code === 'invalid_coupon') {
    throw CouponError.notFound(error.message);
  }

  if (error.code === 'coupon_no_eligible_items') {
    throw CouponError.noEligibleItems(error.message);
  }

  throw CouponError.ineligible(error.message);
}

export function throwGiftCardValidationError(error: SerializedCartValidationError): never {
  if (error.code === 'gift_card_purchasing_gift_card') {
    throw GiftCardError.purchasingGiftCards();
  }

  if (error.code === 'gift_card_invalid_redemption_code') {
    throw GiftCardError.notFound(error.message);
  }

  throw GiftCardError.ineligible(error.message);
}

export function throwReferralCodeValidationError(error: SerializedCartValidationError): never {
  if (error.code === 'invalid_referral_code') {
    throw ReferralCodeError.notFound(error.message);
  }

  if (error.code === 'referral_code_denylisted') {
    throw ReferralCodeError.codeSuspended();
  }

  throw ReferralCodeError.ineligible(error.message);
}

export function throwCartValidationError(validationErrors: CartPricingValidationErrors) {
  if (validationErrors.redeemedGiftCardCode) {
    throwGiftCardValidationError(validationErrors.redeemedGiftCardCode);
  }

  if (validationErrors.couponCode) {
    throwCouponValidationError(validationErrors.couponCode);
  }

  if (validationErrors.referralCode) {
    throwReferralCodeValidationError(validationErrors.referralCode);
  }
}

export function getCustomerMessageFromApolloError(e: any): string | undefined {
  if (isApolloError(e) && e.graphQLErrors.length > 0) {
    const extensions = e.graphQLErrors[0].extensions;
    if (extensions) {
      return extensions.customerMessage;
    }
  }
  return undefined;
}

/**
 * From an error object, determines if the error is an Apollo (GraphQL API) or Axios (REST API) error and re-throws
 * a user-facing error message that contains either the customer-facing message from the API or a fallback message.
 */
export function throwUserFacingOrFallbackError(
  err: Error,
  fallbackError: Error = new UserFacingError(
    'An unknown error occurred. Please try again, or contact support@tryfi.com if the problem persists.',
  ),
  sentryContext?: Omit<Partial<ScopeContext>, 'fingerprint'>,
): never {
  if (isApolloError(err)) {
    const customerMessage = getCustomerMessageFromApolloError(err);
    if (customerMessage) {
      throw new UserFacingError(customerMessage);
    }
  }

  // Error message for fingerprinting Sentry errors, if we make it that far.
  let errorMessage = err.message;

  const USER_FACING_ERROR_CODES = ['customer_error', 'ecommerce_error'];
  if (isAxiosError(err) && err.response?.status === 400 && err.response.data?.error?.message) {
    if (err.response.data.error.code && USER_FACING_ERROR_CODES.includes(err.response.data.error.code)) {
      throw new UserFacingError(err.response.data.error.message);
    }

    if (err.response.data.error.type === 'coupon_code') {
      throwCouponValidationError(err.response.data.error);
    }

    if (err.response.data.error.type === 'redeemed_gift_card_code') {
      throwGiftCardValidationError(err.response.data.error);
    }

    if (err.response.data.error.type === 'referral_code') {
      throwReferralCodeValidationError(err.response.data.error);
    }

    errorMessage = err.response.data.error.message;
  }

  logInternalError(err, {
    ...sentryContext,
    // Separate errors by the message in addition to Sentry's default fingerprint
    fingerprint: ['{{ default }}', errorMessage],
  });
  throw fallbackError;
}

export function logInternalError(err: string | Error, sentryCaptureContext?: CaptureContext) {
  console.error(err);
  const message = typeof err === 'string' ? err : err.message;
  analytics.track('Internal Error', { message });
  Sentry.captureException(err, sentryCaptureContext);
}

export enum ErrorMessages {
  INPUT_ERROR = 'Double check your information and try again, if the problem persists contact support@tryfi.com',
  DEFAULT = 'Reload the page and try again, if the problem persists contact support@tryfi.com',
  PURCHASE_FAILURE = 'We were unable to complete your purchase. Please try again, or contact support@tryfi.com',
}
export default ErrorMessages;
