import React from 'react';
import { Switch } from 'react-router';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import JoinPack from './JoinPack';
import JoinPackComplete from './JoinPackComplete';

export default function Packs() {
  return (
    <Switch>
      <SentryRoute exact path={AppPaths.Packs.Join()} component={JoinPack} />
      <SentryRoute exact path={AppPaths.Packs.Complete()} component={JoinPackComplete} />
    </Switch>
  );
}
