import { MutableRefObject, useState, useEffect } from 'react';

/**
 *
 * @param ref element to track
 * @returns true or false, indicating whether or not user has scrolled completely BELOW the ref element
 */
export default function useScrolledPastElement(ref: MutableRefObject<HTMLDivElement | null>) {
  const [hasScrolledPast, setHasScrolledPast] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (!ref.current) return;

      const rect = ref.current.getBoundingClientRect();
      const scrolledPast = rect.bottom < 0; // Element has completely left the viewport from the top
      setHasScrolledPast(scrolledPast);
    }

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref]);

  return hasScrolledPast;
}
