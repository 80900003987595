/**
 * Integration with the Northbeam marketing platform (https://northbeam.io). Instead of building a custom
 * analytics.js plugin, our integration hooks into analytics.js event emitters to track purchases in Northbeam.
 */
import analytics from '.';
import { getTenancy } from '../../hooks/useTenancy';
import * as types from '../../types';
import { NorthbeamProduct, NorthbeamPurchaseEventProperties } from '../../types/NorthbeamAnalytics';
import { getProductsAndVariantsBySku } from '../product';
import store from '../reduxStore';
import { typedWindow } from '../Window';
import { ECOM_EVENT_ORDER_COMPLETED, SegmentProduct, SegmentPurchaseEventProperties } from './events';

function northbeamPixel() {
  // In production, use the Northbeam Pixel SDK, if available.
  const tenancy = getTenancy();
  if (tenancy !== 'test' && process.env.REACT_APP_ENVIRONMENT === 'production') {
    return (
      typedWindow.Northbeam || {
        firePurchaseEvent: () => {
          // Pixel is blocked in some browsers so it won't exist on the window object. Do nothing.
        },
      }
    );
  }

  // Development environment. Log for debugging.
  return {
    firePurchaseEvent: (properties: NorthbeamPurchaseEventProperties) => {
      console.debug('Northbeam purchase event', properties);
    },
  };
}

/**
 * Map Segment e-commerce cart event properties (multiple products) to Facebook Pixel event properties.
 * We only include products that are S3 collar kits or S3 subscriptions.
 */
function northbeamPurchaseEventProperties(properties: SegmentPurchaseEventProperties) {
  const productsAndVariantsBySku = getProductsAndVariantsBySku(store.getState().config.products);
  const segmentProducts = properties.products || [];

  // Our marketing team only cares about S3 collar kits. Filter products to only include S3 collar kits and S3
  // subscriptions that aren't upgrades.
  const filteredProducts: SegmentProduct[] = segmentProducts.filter((segmentProduct: SegmentProduct) => {
    if (segmentProduct.isUpgrade) {
      return false;
    }

    const productAndVariant = productsAndVariantsBySku.get(segmentProduct.sku);
    if (!productAndVariant) {
      return false;
    }

    const { product } = productAndVariant;
    if (product.id === types.series3CollarId) {
      return true;
    }

    // We'll also include S3 subscriptions since they're bundled with S3 collar kits
    if (
      types.isModuleSubscriptionProduct(product) &&
      product.canBePurchasedForProductIds.includes(types.series3CollarId)
    ) {
      return true;
    }

    return false;
  });

  if (!filteredProducts.length) {
    return;
  }

  const northbeamProducts: NorthbeamProduct[] = [];

  // The Northbeam backend API documentation seems to indicate that productId should be unique so we'll keep
  // things consistent on the frontend and aggregate the products by sku and sum the quantity.
  for (const product of filteredProducts) {
    const existingProduct = northbeamProducts.find((p) => p.productId === product.sku);
    if (existingProduct) {
      existingProduct.quantity += product.quantity;
      continue;
    }

    northbeamProducts.push({
      productId: product.sku,
      variantId: product.sku,
      productName: product.name,
      variantName: product.variant ?? '',
      price: product.price,
      quantity: product.quantity,
    });
  }

  return {
    id: properties.orderId,
    totalPrice: properties.total,
    shippingPrice: properties.shipping ?? 0,
    taxPrice: properties.tax ?? 0,
    coupons: properties.code ?? '',
    customerId: analytics.userOrAnonymousId(),
    currency: 'USD',
    lineItems: northbeamProducts,
  };
}

/**
 * We only need to track purchases for the Northbeam pixel and we only care about S3 collar purchases.
 */
function onTrack(event: string, properties: SegmentPurchaseEventProperties) {
  if (event !== ECOM_EVENT_ORDER_COMPLETED) {
    return;
  }

  // If the order doesn't contain products we care about, the won't be any properties.
  const northbeamProperties = northbeamPurchaseEventProperties(properties);
  if (!northbeamProperties?.lineItems.length) {
    return;
  }

  northbeamPixel().firePurchaseEvent(northbeamProperties);
}

export function northbeamInit() {
  analytics.onTrack(onTrack);
}
