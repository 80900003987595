import classNames from 'classnames';
import React, { useContext } from 'react';
import * as events from '../../lib/analytics/events';
import { centsToDollarsNumber } from '../../lib/util';
import styles from '../../styles/lineItem.module.scss';
import ErrorMessage from '../ErrorMessage';
import RemoveLineItemButton from '../RemoveLineItemButton';
import { useDispatch } from 'react-redux';
import CheckoutContext from '../../lib/CheckoutContext';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import { skusForCartItem } from '../../lib/cart';
import { getProductsBySku } from '../../lib/product';
import * as types from '../../types';
import store from '../../lib/reduxStore';
import { useCartPricing } from '../../contexts/CartPricingContext';
import { SERIES_3_UPGRADE_V2_COUPON_CODE } from '../../views/Series3UpgradeV2/Series3UpgradePDPV2';

interface CouponLineItemProps {
  allowRemoveCoupon?: boolean;
  appliedDiscountInCents?: number;
  couponCode: string;
  validationError?: string | null;
  appliedDiscountInMonths?: number;
}

export function inCartS3CollarCount(
  cartItems: types.StoreCartItem[],
  filters?: {
    includeSkus?: string[] | undefined;
    excludeSkus?: string[] | undefined;
  },
): number {
  let totalQty = 0;
  const products = store.getState().config.products;
  const productsBySku = getProductsBySku(products);
  for (const cartItem of cartItems) {
    const skus = skusForCartItem(cartItem);
    const qty = cartItem.quantity;
    for (const sku of skus) {
      const product = productsBySku.get(sku);
      if (!product) {
        continue;
      }
      if (types.isModuleSubscriptionProduct(product)) {
        if (filters?.includeSkus && !filters.includeSkus.includes(product.sku)) {
          continue;
        }
        if (filters?.excludeSkus && filters.excludeSkus.includes(product.sku)) {
          continue;
        }
        if (product.canBePurchasedForProductIds.includes(types.series3CollarId)) {
          totalQty += qty;
        }
      }
    }
  }
  return totalQty;
}

export function inCartPrepaidMembershipCount(cartItems: types.StoreCartItem[]): number {
  return inCartS3CollarCount(cartItems, {
    excludeSkus: ['sub-monthly-1m-001', 'sub-monthly-3m-001'],
  });
}

export function couponWaivesActivationFee(cartPricing: types.CartPricing, cart: types.Cart): boolean {
  return inCartS3CollarCount(Object.values(cart.cartItems)) > 0 && cartPricing.activationFeesInCents === 0;
}

export default function CouponLineItemV2({
  allowRemoveCoupon,
  couponCode,
  validationError,
  appliedDiscountInMonths,
  appliedDiscountInCents,
}: CouponLineItemProps) {
  const dispatch = useDispatch();
  const { checkoutActions, cart } = useContext(CheckoutContext);
  const contextCartPricing = useCartPricing();

  if (validationError) {
    return null;
  }

  if (couponCode === SERIES_3_UPGRADE_V2_COUPON_CODE) {
    return null;
  }

  const s3CollarCount = inCartS3CollarCount(Object.values(cart.cartItems));
  const shouldWaiveActivationFee = couponWaivesActivationFee(contextCartPricing, cart);

  const isDiscountAmountSetupFee =
    Math.abs(contextCartPricing.totalInCents - contextCartPricing.subtotalInCents) === 2000;
  const isCouponSetupFeeOnly =
    contextCartPricing.couponDiscount?.type === 'dollars' &&
    contextCartPricing.couponDiscount.skus?.includes('setup-fee');

  // Hack - Coupons that only waive activation fee are marked as "setup-fee" in the backend
  const isOnlyActivationFeeWaived =
    s3CollarCount === 1 ? isDiscountAmountSetupFee && isCouponSetupFeeOnly : isCouponSetupFeeOnly;

  return (
    <div className={classNames('coupon-line-item-v2', styles.lineItem, { [styles.noRemove]: !allowRemoveCoupon })}>
      {allowRemoveCoupon && (
        <div className={styles.removeButtonRow}>
          <RemoveLineItemButton
            onClick={() => {
              dispatch(checkoutActions.removeCoupon({}));
              events.cartPage.couponRemoved(couponCode);
            }}
          />
        </div>
      )}
      <div className={styles.itemRow}>
        <div className={classNames(styles.lineItemImage, styles.empty)}></div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            <div className={styles.lineItemPriceRow}>
              <div className={styles.lineItemPromoContainer}>
                <div className={styles.lineItemPromoApplied}>Promo applied: {couponCode.toUpperCase()}</div>
                {!!appliedDiscountInMonths && (
                  <div className={styles.lineItemPrice}>
                    <CheckIcon /> +{appliedDiscountInMonths} extra month{appliedDiscountInMonths > 1 && 's'} FREE ($
                    {appliedDiscountInMonths * 19} value)
                  </div>
                )}
                {!!appliedDiscountInCents && !appliedDiscountInMonths && !isOnlyActivationFeeWaived && (
                  <div className={styles.lineItemPrice}>
                    <CheckIcon /> ${centsToDollarsNumber(appliedDiscountInCents)} off prepaid membership (one time)
                  </div>
                )}
                {shouldWaiveActivationFee && s3CollarCount && (
                  <div className={styles.lineItemPrice}>
                    <CheckIcon /> No activation fee (${s3CollarCount * 20} off)
                  </div>
                )}
              </div>
            </div>
            {!!validationError && (
              <div className={styles.lineItemPriceRow}>
                <ErrorMessage errors={[validationError]} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
