import AddToBagButton from '../../../components/AddToBagButton';
import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import styles from './CollarButtonScrollExperiment.module.scss';
import classNames from 'classnames';
import useScrolledPastElement from '../../../../../hooks/useScrolledPastElement';
import useElementOnScreen from '../../../../../hooks/useElementOnScreen';
import { ReactComponent as ArrowIcon } from '../../../../../assets/images/icons/arrow_up_white.svg';

// Hook that returns whether or not user will see the desktop or tablet/mobile versions of the collar PDP.
function useSmallFormatLayout() {
  const BREAKPOINT_IN_PX = 1025;
  const [isSmallLayout, setIsSmallLayout] = useState(window.innerWidth <= BREAKPOINT_IN_PX);

  useEffect(() => {
    const handleResize = () => setIsSmallLayout(window.innerWidth <= BREAKPOINT_IN_PX);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [BREAKPOINT_IN_PX]);

  return isSmallLayout;
}

interface AddToBagButtonProps {
  disabled?: boolean;
  onAddToBag: () => boolean;
  ctaText?: string;
  descriptionText: string | null;
  fadeInRef?: React.RefObject<HTMLDivElement>;
  hasUpdatedSize?: boolean;
  hasUpdatedSubscriptionSku?: boolean;
  addToCartRef: MutableRefObject<HTMLDivElement | null>;
  sizePickerRef: MutableRefObject<HTMLDivElement | null>;
  membershipPickerRef: MutableRefObject<HTMLDivElement | null>;
  scrollToSizeSelection: () => void;
  scrollToMembershipSelection: () => void;
}

const ADD_TO_CART_COPY = 'Add To Cart';

export default function CollarButtonScrollExperiment({
  disabled,
  onAddToBag,
  ctaText,
  descriptionText,
  fadeInRef,
  hasUpdatedSize,
  hasUpdatedSubscriptionSku,
  addToCartRef,
  sizePickerRef,
  membershipPickerRef,
  scrollToSizeSelection,
  scrollToMembershipSelection,
}: AddToBagButtonProps) {
  const onAddToBagClicked = useCallback(() => {
    if (disabled) {
      return false;
    }

    return onAddToBag();
  }, [disabled, onAddToBag]);

  const [_isVisible, setIsVisible] = useState(false);
  const scrolledBelowSizeOptions = useScrolledPastElement(sizePickerRef);
  const scrolledBelowMembershipOptions = useScrolledPastElement(membershipPickerRef);
  const addToCartButtonIsInViewport = useElementOnScreen(addToCartRef);
  const smallLayout = useSmallFormatLayout();
  // Add to cart button should be sticky only on mobile if button is not disabled and not in viewport.
  const isSticky = smallLayout && !disabled && !addToCartButtonIsInViewport;

  useEffect(() => {
    const handleScroll = () => {
      if (!fadeInRef?.current) return;

      const offsetY = Array.from(document.querySelectorAll('.promotionBanner')).reduce(
        (total, el) => (total + (el as HTMLElement).style.zIndex === '-1' ? 0 : (el as HTMLElement).offsetHeight),
        0,
      );

      const targetTop = fadeInRef.current.getBoundingClientRect().top - offsetY;
      setIsVisible(targetTop < 0);
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [fadeInRef]);

  // Show jump button if user has not selected size and scrolled past size options
  // OR has selected size, has not selected membership, and has scrolled past membership options.
  const showJumpButtons =
    ((!hasUpdatedSize && scrolledBelowSizeOptions) ||
      (hasUpdatedSize && !hasUpdatedSubscriptionSku && scrolledBelowMembershipOptions)) &&
    (scrolledBelowSizeOptions || scrolledBelowMembershipOptions);

  return (
    <div className={classNames(styles.buttonContainer, styles.action)}>
      {ctaText !== ADD_TO_CART_COPY && (
        <div
          className={classNames(styles.jumpButton, {
            [styles.visible]: showJumpButtons,
          })}
          onClick={() => (hasUpdatedSize ? scrollToMembershipSelection() : scrollToSizeSelection())}
        >
          {<ArrowIcon />}
          <span>{ctaText}</span>
        </div>
      )}
      <div ref={addToCartRef} className={classNames(styles.addToCartContainer, styles.inlineCTA)}>
        {!disabled && descriptionText && <div className={styles.descriptionText}>{descriptionText}</div>}
        <div className={styles.addToCartButton}>
          <AddToBagButton disabled={disabled} onAddToBag={onAddToBagClicked} ctaText={ADD_TO_CART_COPY} />
        </div>
      </div>

      {/* Sticky CTA - shows only when inline container is completely out of view */}
      {smallLayout && (
        <div
          className={classNames(styles.addToCartContainer, styles.fixedCTA, {
            [styles.visible]: isSticky,
          })}
        >
          {!disabled && descriptionText && <div className={styles.descriptionText}>{descriptionText}</div>}
          <div className={styles.addToCartButton}>
            <AddToBagButton disabled={disabled} onAddToBag={onAddToBagClicked} ctaText={ADD_TO_CART_COPY} />
          </div>
        </div>
      )}
    </div>
  );
}
