import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import { IImage, IVideo } from '../../types';
import { Carousel, CarouselController } from '../Carousel';
import { Image as FiImage } from '../Image/Image';
import { Video as FiVideo } from '../Video/Video';
import styles from './ImageGallery.module.scss';
import * as events from '../../lib/analytics/events';

export interface ImageGalleryProps {
  media: (IImage | IVideo)[];
  grayBackground?: boolean;
  pageDots?: boolean;
  disableAnimation?: boolean;
  parentCarouselControllerRef?: React.MutableRefObject<CarouselController | undefined>;
  squareAspectRatio?: boolean;
  showMobileGallerySelector?: boolean;
  padGallerySelectorImages?: boolean;
  onChange?: (index: number) => void;
  friction?: number;
  wrapAround?: boolean;
  defaultImageIndex?: number;
}

const FiMedia = ({ media }: { media: IImage | IVideo }) => {
  if (media.type === 'image') {
    return <FiImage image={media} />;
  } else {
    return <FiVideo video={media} />;
  }
};

const ImageGallery = ({
  media,
  grayBackground,
  pageDots,
  disableAnimation,
  parentCarouselControllerRef,
  squareAspectRatio,
  showMobileGallerySelector,
  padGallerySelectorImages,
  onChange,
  friction,
  wrapAround,
  defaultImageIndex,
}: ImageGalleryProps) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(defaultImageIndex ?? 0);
  const fallbackCarouselControllerRef = useRef<CarouselController>();
  const carouselControllerRef = useMemo(
    () => parentCarouselControllerRef ?? fallbackCarouselControllerRef,
    [parentCarouselControllerRef],
  );

  if (media.length === 0) {
    return null;
  }

  if (media.length === 1) {
    return (
      <div
        className={classNames(styles.featuredImageContainer, {
          [styles.grayBackground]: grayBackground,
          [styles.squareAspectRatio]: squareAspectRatio,
        })}
      >
        <FiMedia media={media[0]} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.featuredImageContainer, styles.multipleImages, {
          [styles.grayBackground]: grayBackground,
          [styles.squareAspectRatio]: squareAspectRatio,
        })}
      >
        <Carousel
          cellAlign="center"
          className={styles.featuredImageCarousel}
          initialIndex={selectedImageIndex}
          onChange={(index) => {
            setSelectedImageIndex(index);
            onChange && onChange(index);
            events.series3CollarCarouselInteraction();
          }}
          pageDots={pageDots}
          controllerRef={carouselControllerRef}
          friction={friction}
          wrapAround={wrapAround}
        >
          {media.map((item, i) => (
            <FiMedia media={item} key={i} />
          ))}
        </Carousel>
      </div>
      <div
        className={classNames(
          'more-photos',
          styles.morePhotos,
          showMobileGallerySelector && styles.showMobileGallerySelector,
        )}
      >
        {media.map((item, index) => (
          <div
            onClick={() => {
              carouselControllerRef.current?.select(index, disableAnimation);
            }}
            key={index}
            className={classNames(
              styles.galleryPhoto,
              {
                [styles.selected]: selectedImageIndex === index,
              },
              padGallerySelectorImages && styles.padGallerySelectorImages,
            )}
          >
            {item.type === 'image' ? <FiMedia media={item} /> : <FiMedia media={item.thumbnail} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
