import { gql } from '@apollo/client';

export const billingAccountQuery = gql`
  query billingAccount {
    currentUser {
      id
      firstName
      lastName
      billingAccount {
        firstName
        lastName
        billingInfo {
          firstName
          lastName
          address {
            line1
            line2
            city
            state
            zip
            country
          }
          paymentInfo {
            __typename
            ... on ObfuscatedCardInfo {
              lastFour
              type
              month
              year
            }
          }
        }
        address {
          line1
          line2
          city
          state
          zip
          country
          phone
        }
      }
    }
  }
`;

export const updateBillingInfoMutation = gql`
  mutation updateBillingInfo($input: BillingAccountInput!, $recaptchaToken: String) {
    updateBillingAccount(input: $input, recaptchaToken: $recaptchaToken) {
      billingInfo {
        firstName
        lastName
        address {
          line1
          line2
          city
          state
          zip
          country
        }
        paymentInfo {
          __typename
          ... on ObfuscatedCardInfo {
            lastFour
            type
            month
            year
          }
        }
      }
    }
  }
`;

export const createBillingAccountMutation = gql`
  mutation ECOMMERCE_createUserBillingAccount {
    createBillingAccount(input: {}) {
      code
    }
  }
`;

export const activeBillingSubscriptionsQuery = gql`
  query activeBillingSubscriptions {
    currentUser {
      id
      firstName
      lastName
      billingAccount {
        subscriptions(filter: { state: ACTIVE }) {
          id
        }
      }
    }
  }
`;

export const supplementsSubscriptionsQuery = gql`
  query ECOMMERCE_getSupplementSubscriptionsQuery {
    currentUser {
      id
      hasPurchasedSupplementSubscription
      billingAccount {
        billingInfo {
          paymentInfo {
            __typename
            ... on PayPalInfo {
              billingAgreementId
            }
            ... on ObfuscatedCardInfo {
              lastFour
              type
              month
              year
            }
          }
        }
        subscriptions(filter: { category: SUPPLEMENT, state: ACTIVE }) {
          id
          state
          currentTermEndsAt
          address {
            street1
            street2
            city
            state
            country
            zipcode
          }
          supplementShipmentDates {
            latestSkipDate
            nextShipmentDate
            displayDate
            previewResumingShipmentDate
            previewSkippingShipmentDate
            previewUpdate {
              sku
              nextShipDate
            }
          }
          nextSupplementShipmentDetails {
            status
            deliveryExpected
            trackingLink
            discount
          }
          pendingChanges {
            subscriptionOption {
              weeksBetweenShipments
              sku
            }
          }
          subscriptionOption {
            sku
            priceInCents
            displayedRateUnit
            billingPeriodsPerTerm
            inAppManagementName
            freeTrialDays
            weeksBetweenShipments
            recommendedForDogWeightRangePounds {
              min
              max
            }
          }
        }
      }
    }
  }
`;
