import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import * as events from '../../../lib/analytics/events';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';
import AddToBagButton from '../components/AddToBagButton';
import ProductTitle from '../components/ProductTitle';
import styles from '../ProductDetails.module.scss';

interface ChargingBaseDetailsProps {
  productId: string;
  productName: string;
  priceInCents: number;
  sku: string;
}

export default function ChargingBaseDetails({ productId, productName, priceInCents, sku }: ChargingBaseDetailsProps) {
  useEffect(() => {
    events.chargingBaseViewed({ priceInCents, productName, sku });
  }, [priceInCents, productName, sku]);

  const dispatch = useDispatch();
  const history = useHistory();

  const onAddToBag = useCallback(() => {
    dispatch(storeCartActions.addSingleLineCartItem({ sku }));

    events.chargingBaseAddedToBag({
      priceInCents,
      productName,
      sku,
    });

    history.push(AppPaths.Bag);

    return true;
  }, [dispatch, history, priceInCents, productName, sku]);

  const isDiscontinued = sku === 'F1-BUA';

  return (
    <>
      <div className={styles.productContainer}>
        <ImageGallery
          media={[
            {
              type: 'image',
              url: `/product_images/${productId}.jpg`,
              retinaWidth: 1080,
              retinaHeight: 720,
            },
          ]}
        />
        <div className={styles.productDetails}>
          <div className={styles.productDetailsHeader}>
            <div className={styles.productDetailsTitle}>
              <ProductTitle productName={productName} price={{ priceInCents }} />
            </div>
            <p className={styles.description}>
              This is an extra Fi Base, charging adapter and cable included.
              <br />
              <br />
              Use it to extend the coverage in your home, cover your pup at the office, your beach house or for when
              Rufus spends the weekend at his mom's place.
            </p>
          </div>

          <div className={styles.productAction}>
            {isDiscontinued ? (
              <AddToBagButton onAddToBag={onAddToBag} disabled={true} ctaText="Discontinued" />
            ) : (
              <AddToBagButton onAddToBag={onAddToBag} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
