import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import { CouponCookieData, COUPON_COOKIE_NAME, PARTNER_COOKIE_NAME } from '../lib/util';
import { cartActions as storeCartActions } from '../reducers/storeCart';
import { applyCouponToCart, isValidCodeResult } from '../lib/promoCode';
import useApplyPromotionFromCookie from './useApplyPromotionFromCookie';
import setCouponCookie from '../lib/setCouponCookie';
import setPartnerCookie from '../lib/setPartnerCookie';

export default function useAutoApplyCoupon() {
  const queryParameters = new URLSearchParams(window.location.search);
  const urlPartnerCode = queryParameters.get('pc');

  useEffect(() => {
    if (urlPartnerCode) {
      setCouponCookie(urlPartnerCode);
      setPartnerCookie(true);
    }
  }, [urlPartnerCode]);

  const [bannerText, setBannerText] = useState<string | undefined>(undefined);
  const { finished } = useApplyPromotionFromCookie<CouponCookieData>(
    useMemo(
      () => ({
        getCookieValue: () => {
          const dataValue = Cookies.getJSON(COUPON_COOKIE_NAME);
          if (dataValue) {
            return [
              dataValue,
              () => {
                /* Noop */
              },
            ];
          }

          return undefined;
        },

        applyPromotion: async ({ value, dispatch, cart }) => {
          const result = await applyCouponToCart(cart, value.code);

          // When auto applying coupon, we only don't want to do it if the coupon is not a valid code.
          // If it's other errors (e.g. "Not applicable to any items in cart") then we still want to apply it.
          if (isValidCodeResult(result)) {
            dispatch(storeCartActions.addCoupon(value.code));
            if (value.bannerText) {
              setBannerText(value.bannerText);
            }
          } else {
            Cookies.remove(COUPON_COOKIE_NAME);
            Cookies.remove(PARTNER_COOKIE_NAME);
          }
        },
      }),
      [],
    ),
  );

  return { bannerText, finished };
}
