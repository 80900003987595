import { FeaturesReady } from '@growthbook/growthbook-react';
import React, { useMemo } from 'react';
import Series3CollarDetails from './Series3CollarDetails';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import * as types from '../../../types';
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts';
import ReviewsCarousel from '../../../components/ReviewsCarousel';
import Footer from '../../../components/Footer';
import { PromotionBanner } from '@barkinglabs/promotions-react';
import { PromotionConfigurationKind } from '@barkinglabs/promotions-types';

const getPromoBannerText = (partner: string | null): string | null => {
  if (partner === 'ollie') {
    return '🐶 Ollie Exclusive: Claim Your Fi Collar Gift!';
  }
  return null;
};

const getPromoBannerBackgroundColor = (partner: string | null): string | null => {
  if (partner === 'ollie') {
    return '#FFFF01';
  }
  return null;
};

export const RedeemSeries3Collar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const partner = new URLSearchParams(window.location.search).get('p');
  const promoBannerText = useMemo(() => {
    return getPromoBannerText(partner);
  }, [partner]);
  const promoBannerBackgroundColor = useMemo(() => {
    return getPromoBannerBackgroundColor(partner);
  }, [partner]);

  const products = useSelector((state: types.AppState) => state.config.products);
  const product = useMemo(
    () => products.find((p) => p.id === 'smart-collar-v3') as types.ICollarKitProduct,
    [products],
  );
  const subscriptionProducts = useSubscriptionProducts(types.series3CollarId);
  const filteredSubscriptionProducts = useMemo(
    () => subscriptionProducts.filter((subscriptionProduct) => subscriptionProduct.renewalMonths >= 6),
    [subscriptionProducts],
  );

  return (
    <FeaturesReady>
      {promoBannerText && promoBannerBackgroundColor && (
        <PromotionBanner
          className={'promotionBanner'}
          promotion={{
            configuration: {
              kind: PromotionConfigurationKind.SIMPLE,
              title: promoBannerText,
              extraCss: `.fi-promotion__promoContainer { width: 100%; background-color: ${promoBannerBackgroundColor}; }`,
              countdown: 'none',
              imageUrl: undefined,
              backgroundImageUrl: undefined,
              subtitle: undefined,
              couponCode: undefined,
            },
          }}
          promotionEnd={new Date()}
        />
      )}
      <Series3CollarDetails
        addBundleCartItem={(variantSku, subscriptionSku) => {
          dispatch(
            storeCartActions.addBundleCartItem({
              sku: variantSku,
              subscriptionSku: subscriptionSku,
            }),
          );

          history.push(AppPaths.Bag);
        }}
        priceInCents={product.priceInCents}
        productName={product.name}
        variants={product.variants}
        availableSubscriptions={filteredSubscriptionProducts}
      />
      <ReviewsCarousel />
      <Footer />
    </FeaturesReady>
  );
};
