import { joinPack } from '../../lib/analytics/events';
import React, { useEffect } from 'react';
import styles from './Pack.module.scss';
import Button from '../../components/Button';
import { useLocation } from 'react-router-dom';

export default function JoinPackComplete() {
  useEffect(() => joinPack.successfullyJoinedPack(), []);

  const {
    state: { packName },
  } = useLocation<{ packName?: string }>();

  return (
    <div className={styles.simpleFullWidthWrapper}>
      <div className={styles.simpleContainer}>
        <div className={styles.simpleSpacer} />
        <div className={styles.simpleHeaderContainer}>
          <h1 className={styles.simpleHeader}>Thank You!</h1>
          <p className={styles.simpleSubheader}>You've successfully joined {packName ? packName : 'the pack'}</p>
        </div>
        <Button
          className={styles.simpleButton}
          onClick={() => {
            window.open('fi://closeappview');
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
