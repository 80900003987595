import Cookies from 'js-cookie';
import { DEV_MODE } from './devMode';

export interface PartnerCookieData {
  isPartner: boolean;
}

export function removePartnerCookie(name: string) {
  Cookies.remove(name, { domain: DEV_MODE ? undefined : '.tryfi.com' });
}

export const PARTNER_COOKIE_NAME = 'fi_partner';
export const PARTNER_COOKIE_EXPIRATION_HOURS = 72;
