import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApplePayCheckout from '../../components/ApplePayCheckout/ApplePayCheckout';
import ErrorMessage, { useError } from '../../components/ErrorMessage/ErrorMessage';
import Loading from '../../components/Loading/Loading';
import WebViewAppBar from '../../components/WebViewAppBar/WebViewAppBar';
import HideChatWidget from '../../components/ZendeskChat/HideChatWidget';
import { useCartPricing } from '../../contexts/CartPricingContext';
import { billingAccountQuery, updateBillingInfoMutation } from '../../graphql-operations';
import { osSupportsApplePay } from '../../hooks/useShouldShowApplePay';
import * as events from '../../lib/analytics/events';
import { isForModuleCartItem } from '../../lib/cart';
import { useSubscriptionCartMode } from '../../lib/cartModes';
import { ErrorMessages, getCustomerMessageFromApolloError, logInternalError } from '../../lib/errors';
import { formattedCentsToDollars } from '../../lib/util';
import { cartActions } from '../../reducers/subscriptionCart';
import * as types from '../../types';
import { gqlTypes } from '../../types';
import { UpdateBillingInfoFunction } from '../Checkout/pages/Billing/BillingInfo';
import EditCreditCardBillingInfo from '../Checkout/pages/Billing/EditCreditCardBillingInfo';
import { usePurchaseSubscriptionContext } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import styles from './PrepaidActivation.module.scss';
import PrepaidSubscriptionInfo from './PrepaidSubscriptionInfo';
import useActivateSubscription from './useActivateSubscription';
import usePrepaidSubscriptionOption from './usePrepaidSubscriptionOption';

interface CreditCardFormProps {
  activateSubscriptionLoading: boolean;
  activateSubscription: () => Promise<void>;
  onSuccess: () => void;
  error?: string;
  errorID?: string;
  setError: (message: string) => void;
  clearError: () => void;
  renewalMonths: number;
  renewalFormattedPrice: string;
  nextBillingDate: string;
}

function subtextForOrderSource(orderSource: gqlTypes.OrderSource) {
  switch (orderSource) {
    case gqlTypes.OrderSource.AMAZON:
      return 'Prepaid with your Amazon purchase';
    case gqlTypes.OrderSource.COSTCO:
      return 'Prepaid with your Costco purchase';
    case gqlTypes.OrderSource.RECURLY:
      return 'Included in your gift';
    default:
      return undefined;
  }
}

export function CreditCardForm({
  activateSubscriptionLoading,
  activateSubscription,
  onSuccess,
  error,
  errorID,
  setError,
  clearError,
  renewalMonths,
  renewalFormattedPrice,
  nextBillingDate,
}: CreditCardFormProps) {
  const showApplePay = useSelector(
    (state: types.AppState) => !!(state.config.siteConfig.showApplePay && osSupportsApplePay()),
  );

  const [mutation, { loading: updateBillingMutationLoading }] = useMutation<
    gqlTypes.updateBillingInfo,
    gqlTypes.updateBillingInfoVariables
  >(updateBillingInfoMutation);

  const handleUpdateBillingInfo: UpdateBillingInfoFunction = useCallback(
    async ({ input, recaptchaToken }) => {
      if (updateBillingMutationLoading || activateSubscriptionLoading) {
        return;
      }

      events.prepaidActivation.submitCreditCard();

      // Update the billing info first
      try {
        await mutation({
          variables: {
            input,
            recaptchaToken,
          },
          refetchQueries: [{ query: billingAccountQuery }],
        });
      } catch (err) {
        const customerMessage = getCustomerMessageFromApolloError(err);
        if (customerMessage) {
          setError(customerMessage);
        } else {
          logInternalError(err, {
            fingerprint: ['{{ default }}', err.message],
            tags: {
              checkoutStep: 'updateBillingInfo',
              context: 'giftFiActivation',
              paymentMethod: 'creditCard',
            },
          });

          setError(ErrorMessages.DEFAULT);
        }

        return;
      }

      // Then create the subscription
      try {
        await activateSubscription();
      } catch (err) {
        const customerMessage = getCustomerMessageFromApolloError(err);
        if (customerMessage) {
          console.log('customerMessage', customerMessage);
          setError(customerMessage);
        } else {
          logInternalError(err, {
            fingerprint: ['{{ default }}', err.message],
            tags: {
              checkoutStep: 'activateSubscription',
              context: 'giftFiActivation',
              paymentMethod: 'creditCard',
            },
          });

          setError(ErrorMessages.DEFAULT);
        }
      }
    },
    [updateBillingMutationLoading, activateSubscriptionLoading, mutation, activateSubscription, setError],
  );

  return (
    <>
      <EditCreditCardBillingInfo
        actionText="Save"
        updateBillingInfo={handleUpdateBillingInfo}
        billingInfo={null}
        submitting={updateBillingMutationLoading || activateSubscriptionLoading}
        setError={setError}
        clearError={clearError}
        compactForm
        actionNote={
          <>
            {error && <ErrorMessage errors={[error]} errorID={errorID} />}
            <div className={styles.renewalNotice}>
              Your membership will renew on {nextBillingDate} for {renewalMonths} months ({renewalFormattedPrice}
              ). You can change this in your Fi account.
            </div>
          </>
        }
        additionalActions={
          <>
            {showApplePay && (
              <ApplePayCheckout
                errorAlertOnAccountExists
                events={events.prepaidActivation}
                onError={(err) => setError(err.message)}
                onSuccess={onSuccess}
                showNonSafariApplePayButton={false}
              />
            )}
          </>
        }
      />
    </>
  );
}

export default function PrepaidActivationCreditCard() {
  const { cartSelector } = useSubscriptionCartMode();
  const { device } = usePurchaseSubscriptionContext();
  const { error, errorID, setError, clearError } = useError();
  const cart = useSelector(cartSelector);
  const dispatch = useDispatch();
  const prepaidSubscriptionInfo = usePrepaidSubscriptionOption();
  const cartPricing = useCartPricing();

  const {
    activateSubscription,
    loading: activateSubscriptionLoading,
    onSuccess,
  } = useActivateSubscription({
    setError,
  });

  useEffect(() => {
    if (!prepaidSubscriptionInfo) {
      return;
    }

    // If there's already an item in this cart from browser local storage, be sure to overwrite it with the correct
    // module id and sku
    const cartItems = Object.values(cart.cartItems);
    if (
      !cartItems.length ||
      cartItems[0].lineItem.sku !== prepaidSubscriptionInfo.subscriptionOptionSku ||
      (isForModuleCartItem(cartItems[0]) && cartItems[0].forModuleId !== device.moduleId)
    ) {
      dispatch(
        cartActions.setCartItem({
          moduleId: device.moduleId,
          sku: prepaidSubscriptionInfo.subscriptionOptionSku,
        }),
      );
    }
  }, [cart.cartItems, device.moduleId, dispatch, prepaidSubscriptionInfo]);

  if (!prepaidSubscriptionInfo) {
    return (
      <div className={styles.container}>
        <WebViewAppBar />
        <ErrorMessage
          errors={[
            'We were unable to find the prepaid membership for this collar or your prepaid membership has already been activated. Please contact support@tryfi.com.',
          ]}
        />
      </div>
    );
  }

  if (!Object.keys(cart.cartItems).length) {
    return <Loading />;
  }

  const { subscriptionOptionName, formattedPrice, renewalSubscriptionOption, renewalFormattedPrice, nextBillingDate } =
    prepaidSubscriptionInfo;

  const formattedDueToday = formattedCentsToDollars(cartPricing.totalInCents);

  return (
    <div className={styles.container}>
      <WebViewAppBar title="Complete Activation" />
      <div className={styles.content}>
        <div className={styles.section}>
          <PrepaidSubscriptionInfo
            subscriptionOptionName={subscriptionOptionName}
            price={formattedPrice}
            subtext={subtextForOrderSource(prepaidSubscriptionInfo.orderSource)}
            dueToday={formattedDueToday}
          />
        </div>
        <div className={classNames(styles.section, styles.billing)}>
          <CreditCardForm
            activateSubscriptionLoading={activateSubscriptionLoading}
            activateSubscription={activateSubscription}
            onSuccess={onSuccess}
            error={error}
            errorID={errorID}
            setError={setError}
            clearError={clearError}
            renewalMonths={renewalSubscriptionOption.renewalMonths}
            renewalFormattedPrice={renewalFormattedPrice}
            nextBillingDate={nextBillingDate}
          />
        </div>
      </div>
      <HideChatWidget />
    </div>
  );
}
