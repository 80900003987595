import React from 'react';
import USStateSelector from '../../../../components/USStateDropdown';
import useCheckoutPaths from '../../../../hooks/useCheckoutPaths';
import LoginButton from '../../components/LoginButton';
import styles from './Shipping.module.scss';

interface ChangeHandler {
  onChange(fieldName: string, value: string): void;
}

interface AccountFields {
  email?: string;
}

interface GiftingAccountFields {
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface ContactInfoFields {
  firstName?: string;
  lastName?: string;
  phone?: string;
  showPhoneNumberInput: boolean;
}

interface GiftingContactInfoFields {
  giftFirstName?: string;
  giftLastName?: string;
}

interface ShippingAddressFields {
  line1?: string;
  line2?: string;
  city?: string;
  zip?: string;
  state?: string;
  phone?: string;
}

interface ShippingAddressDetailsProps extends ShippingAddressFields, ChangeHandler {
  title: string;
  includePhoneField?: boolean;
}

export function AccountDetails({ email, onChange }: AccountFields & ChangeHandler) {
  const checkoutPaths = useCheckoutPaths();

  return (
    <div className={styles.formSection}>
      <h4>Create an account</h4>
      <input
        type="email"
        name="email"
        placeholder="Email address"
        value={email}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="email"
        data-hj-whitelist
      />
      <input
        type="password"
        name="password"
        placeholder="Password"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="new-password"
      />
      <div className={styles.loginButton}>
        <LoginButton returnTo={checkoutPaths.Shipping} />
      </div>
    </div>
  );
}

export function ContactInfo({
  onChange,
  firstName,
  lastName,
  phone,
  showPhoneNumberInput: requiresShippingDetails,
}: ContactInfoFields & ChangeHandler) {
  return (
    <div className={styles.formSection}>
      <h4>Contact info</h4>
      <div className={styles.formRow}>
        <input
          type="text"
          name="firstName"
          placeholder="First name"
          value={firstName}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          autoComplete="given-name"
          data-hj-whitelist
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last name"
          value={lastName}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          autoComplete="family-name"
          data-hj-whitelist
        />
      </div>
      {requiresShippingDetails && (
        <input
          type="tel"
          name="phone"
          placeholder="Phone number"
          value={phone}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          autoComplete="tel"
          data-hj-whitelist
        />
      )}
    </div>
  );
}

export function ShippingAddressDetails({
  onChange,
  line1,
  line2,
  city,
  zip,
  state,
  phone,
  includePhoneField,
  title,
}: ShippingAddressDetailsProps) {
  return (
    <div className={styles.formSection}>
      <h4>{title}</h4>
      <input
        type="text"
        name="line1"
        placeholder="Street address"
        value={line1}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="address-line1"
        data-hj-whitelist
      />
      <input
        type="text"
        name="line2"
        placeholder="Apt/suite/other (optional)"
        value={line2}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="address-line-2"
        data-hj-whitelist
      />
      <input
        type="text"
        name="city"
        placeholder="City"
        value={city}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="address-level2"
        data-hj-whitelist
      />
      <div className={styles.formRow}>
        <USStateSelector state={state} onChange={(newState) => onChange('state', newState)} />
        <input
          type="text"
          name="zip"
          placeholder="Zip Code"
          value={zip}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          autoComplete="postal-code"
          data-hj-whitelist
        />
      </div>
      {includePhoneField && (
        <>
          <input
            type="tel"
            name="phone"
            placeholder="Phone number"
            value={phone}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            autoComplete="tel"
            data-hj-whitelist
          />
          <div className={styles.phoneNumberDescription}>
            A phone number can be helpful if there are any delivery questions from the carrier.
          </div>
        </>
      )}
    </div>
  );
}

export function GiftingAccountDetails({ firstName, lastName, email, onChange }: GiftingAccountFields & ChangeHandler) {
  const checkoutPaths = useCheckoutPaths();

  return (
    <div className={styles.formSection}>
      <h4>Create an account</h4>
      <input
        type="text"
        name="firstName"
        placeholder="First name"
        value={firstName}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="given-name"
        data-hj-whitelist
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last name"
        value={lastName}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="family-name"
        data-hj-whitelist
      />
      <input
        type="email"
        name="email"
        placeholder="Email address"
        value={email}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="email"
        data-hj-whitelist
      />
      <input
        type="password"
        name="password"
        placeholder="Password"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="new-password"
      />
      <div className={styles.loginButton}>
        <LoginButton returnTo={checkoutPaths.Shipping} />
      </div>
    </div>
  );
}

export function GiftingContactInfo({
  onChange,
  giftFirstName,
  giftLastName,
}: GiftingContactInfoFields & ChangeHandler) {
  return (
    <div className={styles.formSection}>
      <h4>Recipient’s information</h4>
      <input
        type="text"
        name="giftFirstName"
        placeholder="First name"
        value={giftFirstName}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="given-name"
        data-hj-whitelist
      />
      <input
        type="text"
        name="giftLastName"
        placeholder="Last name"
        value={giftLastName}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="family-name"
        data-hj-whitelist
      />
    </div>
  );
}
