import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { updateShippingAddressMutation, billingAccountQuery } from '../../../../graphql-operations';
import { gqlTypes } from '../../../../types';
import ErrorMessage, { useError } from '../../../../components/ErrorMessage';
import Loading from '../../../../components/Loading';
import useCheckoutPaths from '../../../../hooks/useCheckoutPaths';
import * as events from '../../../../lib/analytics/events';
import CheckoutContext from '../../../../lib/CheckoutContext';
import ErrorMessages, { getCustomerMessageFromApolloError, logInternalError } from '../../../../lib/errors';
import CheckoutSection from '../../../../models/CheckoutSection';
import * as types from '../../../../types';
import StepContainer from '../../components/StepContainer';
import AddressConfirmationModal from './AddressConfirmationModal';
import styles from './Shipping.module.scss';
import ShippingAddressForm from './ShippingAddressForm';
import useAddressValidation from './useAddressValidation';
import useShippingOptions from '../../../../hooks/useShippingOptions';
import { isExpeditedShippingAvailable } from '../../../../lib/shipping';
import { DEFAULT_SHIPPING_OPTION } from '../../../../reducers/checkout';
import { priceCartForPreview } from '../../../../lib/pricing';
import { CartPricingProvider } from '../../../../contexts/CartPricingContext';
import classNames from 'classnames';
import { cartActions as storeCartActions } from '../../../../reducers/storeCart';
import { fetchRecaptchaToken } from '../../../../lib/recaptcha';

interface AddressUpdate {
  firstName: string;
  lastName: string;
  address: types.Address;
  addressValidationStatus: types.AddressValidationNeedsVerify;
  shippingCode: types.ShippingCode;
  giftRecipient: {
    firstName: string;
    lastName: string;
  } | null;
}

interface LocationState {
  billingToken?: string;
}

export default function Shipping() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, errorID, setError } = useError();
  const checkoutPaths = useCheckoutPaths();
  const shippingOptions = useShippingOptions();
  const { cart } = useContext(CheckoutContext);
  const fetchPricing = useCallback(() => priceCartForPreview(cart), [cart]);
  const [recipientIsGiftee, setRecipientIsGiftee] = useState<boolean>(false);

  // For users who selected PayPal on the cart page but were not logged in yet, we'll have a billing token
  // that we want to set on the billing account as soon as they have one.
  const location = useLocation<LocationState>();
  const billingToken = location.state?.billingToken;

  const addressValidationEnabled = useSelector(
    (state: types.AppState) => state.config.siteConfig.addressValidationEnabled,
  );

  const { session, requiresShippingDetails, checkoutState, checkoutActions } = useContext(CheckoutContext);
  const addressValidationStatus = useMemo(
    () => checkoutState.addressValidationStatus,
    [checkoutState.addressValidationStatus],
  );

  const loggedIn = !!session;

  const { data: billingAccountData, loading: queryLoading } = useQuery<gqlTypes.billingAccount>(billingAccountQuery, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      const customerErrorMessage = getCustomerMessageFromApolloError(err);
      if (customerErrorMessage) {
        setError(customerErrorMessage);
      } else {
        logInternalError(err, {
          fingerprint: ['{{ default }}', err.message],
          tags: {
            checkoutStep: 'shipping',
          },
        });
        setError(ErrorMessages.DEFAULT);
      }
    },
    onCompleted: (data) => {
      if (!data?.currentUser?.billingAccount) {
        return;
      }

      const { firstName, lastName, address: addressResult } = data.currentUser.billingAccount;

      const resolvedFirstName = firstName ?? data.currentUser.firstName;
      if (!checkoutState.customerName) {
        dispatch(checkoutActions.setCustomerName({ firstName: resolvedFirstName, lastName }));
      }

      if (!checkoutState.shippingAddress && addressResult) {
        const { __typename, ...address } = addressResult;
        dispatch(checkoutActions.setShippingAddress({ shippingAddress: address }));
      }
    },
    // Don't bother trying to query user's billing account if we know they aren't logged in
    skip: !loggedIn,
  });

  const [mutation, { loading: mutationLoading }] = useMutation<
    gqlTypes.updateShippingAddress,
    gqlTypes.updateShippingAddressVariables
  >(updateShippingAddressMutation);

  const saveConfirmedShipping = useCallback(
    (shippingInfo: types.ShippingInfo) => {
      const handleShippingSuccess = (address: any) => {
        dispatch(checkoutActions.setCustomerName(shippingInfo.customerName));
        dispatch(checkoutActions.setShippingAddress({ shippingAddress: address ?? undefined }));

        if (addressValidationEnabled) {
          dispatch(checkoutActions.setAddressValidationStatus(types.AddressValidationStatus.Verified));
        }

        const selectedShippingOption = shippingOptions.find((so) => so.code === checkoutState.shippingCode);
        const canExpedite = isExpeditedShippingAvailable(shippingInfo.address);
        if (selectedShippingOption?.isExpedited && !canExpedite) {
          dispatch(checkoutActions.setShippingCode({ shippingCode: DEFAULT_SHIPPING_OPTION }));
        }

        history.push(checkoutPaths.Payment);
      };

      if (recipientIsGiftee) {
        dispatch(
          storeCartActions.addGiftShippingAddress({
            firstName: shippingInfo.customerName.firstName,
            lastName: shippingInfo.customerName.lastName || '',
            city: shippingInfo.address.city,
            phone: shippingInfo.address.phone,
            state: shippingInfo.address.state,
            street1: shippingInfo.address.line1,
            street2: shippingInfo.address.line2,
            zipcode: shippingInfo.address.zip,
          }),
        );
        handleShippingSuccess(shippingInfo.address);
        return;
      }

      // Billing token is only present if the user selected PayPal on the cart page but was not logged in yet.
      // A reCAPTCHA token is only required for this mutation when we need to update payment info.
      const maybeFetchRecaptchaToken = () => {
        if (billingToken) {
          return fetchRecaptchaToken('payment_add');
        }

        return Promise.resolve(null);
      };

      maybeFetchRecaptchaToken()
        .then((recaptchaToken) =>
          mutation({
            variables: {
              ...shippingInfo.customerName,
              address: shippingInfo.address,
              billingInfo: billingToken ? { token: billingToken } : null,
              recaptchaToken,
            },
          }),
        )
        .then(({ data: mutationResults }) => {
          const { address: savedAddress } = mutationResults?.updateBillingAccount ?? {};
          const { __typename, ...address } = savedAddress ?? {};

          handleShippingSuccess(address);
        })
        .catch((err) => {
          const customerErrorMessage = getCustomerMessageFromApolloError(err);
          if (customerErrorMessage) {
            setError(customerErrorMessage);
          } else {
            logInternalError(err, {
              fingerprint: ['{{ default }}', err.message],
              tags: {
                checkoutStep: 'updateShippingAddress',
              },
            });
            setError(ErrorMessages.INPUT_ERROR);
          }

          events.shipping.continueError(err.message);
        });
    },
    [
      recipientIsGiftee,
      mutation,
      billingToken,
      dispatch,
      checkoutActions,
      addressValidationEnabled,
      shippingOptions,
      history,
      checkoutPaths.Payment,
      checkoutState.shippingCode,
      setError,
    ],
  );

  const {
    addressValidationLoading,
    executeAddressValidation,
    pendingAddressUserConfirmation,
    setPendingAddressUserConfirmation,
    unconfirmedShipping,
  } = useAddressValidation({
    addressValidationStatus,
    saveConfirmedShipping,
  });

  if (loggedIn && !requiresShippingDetails) {
    return <Redirect to={checkoutPaths.Payment} />;
  }

  if (queryLoading) {
    return <Loading />;
  }

  const submitting = mutationLoading || addressValidationLoading;

  return (
    <CartPricingProvider fetchPricing={fetchPricing}>
      <StepContainer checkoutSection={CheckoutSection.shipping}>
        <div className={classNames('shipping', styles.container)}>
          <ShippingAddressForm
            loggedInUserId={billingAccountData?.currentUser?.id}
            submitting={submitting}
            onComplete={(addressUpdate?: AddressUpdate) => {
              if (addressUpdate) {
                const {
                  firstName,
                  lastName,
                  address: submittedAddress,
                  addressValidationStatus: newAddressValidationStatus,
                  shippingCode,
                  giftRecipient,
                } = addressUpdate;

                dispatch(checkoutActions.setShippingCode({ shippingCode }));
                setRecipientIsGiftee(!!giftRecipient);

                const shippingInfo: types.ShippingInfo = {
                  customerName: giftRecipient || { firstName, lastName },
                  address: submittedAddress,
                };

                if (addressValidationEnabled) {
                  executeAddressValidation(shippingInfo, newAddressValidationStatus);
                } else {
                  saveConfirmedShipping(shippingInfo);
                }
              } else {
                // If there was no address update, shipping info is not required and the ShippingAddressForm
                // has already handled the user account submission.
              }
            }}
          />
          {error && <ErrorMessage errors={[error]} errorID={errorID} />}
        </div>
        {unconfirmedShipping && (
          <AddressConfirmationModal
            open={!!pendingAddressUserConfirmation}
            inputAddress={unconfirmedShipping?.address ?? null}
            matchedAddress={pendingAddressUserConfirmation?.matchedAddress ?? null}
            status={pendingAddressUserConfirmation?.status ?? null}
            onCancel={() => {
              setPendingAddressUserConfirmation(null);
            }}
            onSubmit={(submittedAddress: types.Address) => {
              setPendingAddressUserConfirmation(null);
              saveConfirmedShipping({
                customerName: unconfirmedShipping.customerName,
                address: submittedAddress,
              });
            }}
          />
        )}
      </StepContainer>
    </CartPricingProvider>
  );
}
