import React from 'react';

import Question from '../../../../ProductDetails/Series3CollarDetails/components/FAQ/Question';
import styles from '../../../../ProductDetails/Series3CollarDetails/components/FAQ/FAQ.module.scss';
import productInfoStyles from '../ProductInfo/ProductInfo.module.scss';
import classNames from 'classnames';

export default function FAQ() {
  return (
    <div className={classNames(styles.container, productInfoStyles.faqContainer)}>
      <h2 className={styles.heading}>FAQ</h2>
      <div>
        <Question title="Can I give my dog this alongside other supplements and vitamins?" page="Supplements">
          Every supplement in our lineup is carefully formulated to avoid known interactions with each other, supporting
          your dog’s health without compromise. While our products are designed with safety in mind, it’s always a good
          idea to check with your vet when combining supplements. They can help tailor the right approach for your dog’s
          unique needs.
        </Question>
        <Question title="Is this included in my collar membership or is it a separate bill?" page="Supplements">
          Fi Supplements are billed independently of your monthly Fi Collar membership and can be purchased as an
          auto-shipment or single-bag order.
          <br />
          <br />
          For auto-shipments, you’ll receive a separate bill and can make changes to your shipping frequency outside
          your Fi Collar membership.
        </Question>
        <Question title="What is my level of commitment for auto-shipments?" page="Supplements">
          Auto-shipments can be cancelled at anytime.
          <br />
          <br />
          Reach out to customer service or modify your shipping frequency in the app to adjust your order preferences.
        </Question>

        <Question title="What is my level of commitment?" page="Supplements">
          This is an ongoing subscription, and you can cancel anytime.
          <br />
          <br />
          Reach out to customer service to modify your subscription.
        </Question>
        <Question title="What is the delivery schedule?" page="Supplements">
          You’ll receive your first order soon, and a new bag will arrive every 1 to 12 weeks, depending on your dog’s
          size and selected auto-ship frequency.
        </Question>
      </div>
    </div>
  );
}
