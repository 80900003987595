import { gql } from '@apollo/client';

export const getPackQuery = gql`
  query ECOMMERCE_pack($packId: ID!) {
    pack(id: $packId) {
      id
      name
    }
  }
`;

export const joinPackMutation = gql`
  mutation ECOMMERCE_joinPack($petId: ID!, $packId: ID!) {
    joinPack(petId: $petId, packId: $packId) {
      id
    }
  }
`;
