import Cookies from 'js-cookie';
import { PartnerCookieData, PARTNER_COOKIE_NAME, DEV_MODE, PARTNER_COOKIE_EXPIRATION_HOURS } from './util';
import { DateTime } from 'luxon';

export default function setPartnerCookie(isPartner: boolean) {
  const data: PartnerCookieData = { isPartner };
  Cookies.set(PARTNER_COOKIE_NAME, JSON.stringify(data), {
    expires: DateTime.utc().plus({ hours: PARTNER_COOKIE_EXPIRATION_HOURS }).toJSDate(),
    domain: DEV_MODE ? undefined : 'tryfi.com',
    secure: !DEV_MODE,
  });
}
