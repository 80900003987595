import React from 'react';
import ConfirmModal from '../../components/modals/ConfirmModal';

interface IConfirmModalProps {
  packName?: string;
  trigger: JSX.Element;
  onAccept?: () => void;
  onDecline?: () => void;
}

export default function JoinPackConfirmationModal({
  packName,
  trigger,
  onAccept = () => {},
  onDecline = () => {},
}: IConfirmModalProps) {
  return (
    <ConfirmModal
      trigger={trigger}
      body={
        <>
          <br />
          <h3>Join {packName ? packName : 'the pack'}</h3>
        </>
      }
      acceptText={'Confirm'}
      declineText={'Go back'}
      onAccept={() => {
        onAccept();
      }}
      onDecline={() => {
        onDecline();
      }}
    />
  );
}
