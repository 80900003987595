import { Alert } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import IconAlert from '../../../assets/images/icons/icon_alert.svg';
import { billingAccountQuery, updateBillingInfoMutation } from '../../../graphql-operations';
import { gqlTypes } from '../../../types';
import { useError } from '../../../components/ErrorMessage';
import Loading from '../../../components/Loading';
import ErrorMessages, { getCustomerMessageFromApolloError, logInternalError } from '../../../lib/errors';
import BillingInfo, { UpdateBillingInfoFunction } from '../../Checkout/pages/Billing/BillingInfo';
import styles from './PurchaseSubscriptionPayment.module.scss';
import ApplePayCheckout from '../../../components/ApplePayCheckout';
import * as events from '../../../lib/analytics/events';

interface PurchaseSubscriptionPaymentBodyProps {
  onSubmit?: () => void;
  onCancel?: () => void;
  includeCancel?: boolean;
  showApplePay?: boolean;
  actionText?: string;
  skipAccountCheck?: boolean;
}

export default function PurchaseSubscriptionPaymentBody({
  onSubmit,
  onCancel,
  includeCancel,
  showApplePay,
  actionText,
  skipAccountCheck,
}: PurchaseSubscriptionPaymentBodyProps) {
  const { error, setError, clearError } = useError();
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const [mutation, { loading: mutationLoading }] = useMutation<
    gqlTypes.updateBillingInfo,
    gqlTypes.updateBillingInfoVariables
  >(updateBillingInfoMutation);

  const { data: accountData, loading: accountLoading } = useQuery<gqlTypes.billingAccount>(billingAccountQuery, {
    onError(err) {
      if (skipAccountCheck) return;
      logInternalError(err);
      setError(ErrorMessages.DEFAULT);
    },
  });

  const [updatingBillingInfo, setUpdatingBillingInfo] = useState(false);

  const handleUpdateBillingInfo: UpdateBillingInfoFunction = useCallback(
    async ({ input, recaptchaToken }) => {
      if (updatingBillingInfo) {
        return;
      }

      setUpdatingBillingInfo(true);
      try {
        await mutation({
          variables: {
            input,
            recaptchaToken,
          },
          refetchQueries: [{ query: billingAccountQuery }],
        });

        onSubmit && onSubmit();
        setSuccess(true);
      } catch (err) {
        const customerMessage = getCustomerMessageFromApolloError(err);
        if (customerMessage) {
          setError(customerMessage);
        } else {
          logInternalError(err, {
            fingerprint: ['{{ default }}', err.message],
            tags: {
              context: 'purchaseSubscription',
              checkoutStep: 'updateBillingInfo',
            },
          });
          setError(ErrorMessages.DEFAULT);
        }
      } finally {
        setUpdatingBillingInfo(false);
      }
    },
    [mutation, updatingBillingInfo, setUpdatingBillingInfo, setError, onSubmit],
  );

  const isLoading = accountLoading || mutationLoading || updatingBillingInfo;

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className={styles.billingInfoContainer}>
        {success && <div className={styles.success}>Payment updated successfully</div>}
        {(accountData || skipAccountCheck) && (
          <BillingInfo
            billingInfo={accountData?.currentUser.billingAccount?.billingInfo ?? null}
            setError={setError}
            clearError={clearError}
            submitting={mutationLoading}
            handleNoChanges={() => {}}
            updateBillingInfo={handleUpdateBillingInfo}
            shippingAddress={undefined}
            startInEditMode
            compactForm
            includeCancel={includeCancel}
            onCancelEditMode={() => {
              onCancel && onCancel();
              // if there is history, go back; otherwise, this is a fresh webview, so close it
              history.length > 1 ? history.goBack() : window.open('fi://closeappview');
            }}
            actionText={actionText ?? 'Save'}
            outlineErrorFields={true}
            additionalActions={
              <>
                {showApplePay && (
                  <ApplePayCheckout
                    errorAlertOnAccountExists
                    events={events.cartPage}
                    onError={(err) => setError(err.message)}
                    onSuccess={onSubmit}
                    showNonSafariApplePayButton={false}
                  />
                )}
              </>
            }
          />
        )}
      </div>
      {error && (
        <Alert
          style={{
            background: '#E0352A',
            color: '#FFFFFF',
            borderRadius: '12px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '120%',
          }}
          className={styles.errorAlert}
          icon={<img className={styles.errorAlertIcon} src={IconAlert} alt="!" />}
          action={
            <div className={styles.errorAlertDismiss} onClick={() => clearError()}>
              Dismiss
            </div>
          }
        >
          There was an error saving your info, recheck and try again.
        </Alert>
      )}
    </>
  );
}
